import React from "react";
import {Navbar} from "../../components";
import "./style.scss";

const ComingSoon = () => {
    return (
        <div className="animate__animated animate__fadeIn animate__fast">
            <Navbar />
            <div className="coming-soon">
                <div className="coming-soon__box animate__animated animate__fadeIn">
                    <div className="coming-soon__title">
                        <h1>
                            COMING
                            <br />
                            SOON<span>...</span>
                        </h1>
                        <p>
                            Si estás aquí, de seguro tienes un emprendimiento,
                            empresa, proyecto o estás en busca de empleo.
                            Tenemos diversas opciones para ayudarte.
                            <br />
                        </p>
                        <p className="">
                            Esta página está en construcción. Nos estamos
                            preparando para darte la mejor experiencia.
                        </p>
                    </div>
                    <div className="coming-soon__contact">
                        <p>Contáctanos</p>
                        <div className="coming-soon__contact-icons">
                            <a
                                className="icons-anchor"
                                href="https://www.instagram.com/boosthumans/"
                            >
                                <i className="fa-brands fa-instagram icons-ico visible"></i>
                            </a>
                            <a
                                className="icons-anchor"
                                href="https://wa.link/e66the"
                            >
                                <i className="fa-brands fa-whatsapp icons-ico"></i>
                            </a>
                            <a
                                className="icons-anchor"
                                href="mailto:contacto@boosthumans.com"
                            >
                                <i className="fa-regular fa-envelope icons-ico"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;
